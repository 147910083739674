/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";

import "/src/scss/styles.scss";
import "lazysizes";

//import '/src/js/general.functions.js'

//import  'prebid.js';

//Load Bid Adapaters
// import 'prebid.js/modules/aolBidAdapter'; //One by AOL
// import 'prebid.js/modules/openxBidAdapter'; //OpenX
// import 'prebid.js/modules/tripleliftBidAdapter'; //Triplelift
// import 'prebid.js/modules/yieldoneBidAdapter'; //YeildOne
// //import 'prebid.js/modules/yahoosspBidAdapter'; //Yahoo SSP
// import 'prebid.js/modules/appnexusBidAdapter'; //Appnexus
// import 'prebid.js/modules/sovrnBidAdapter'; //Sovrn
// import 'prebid.js/modules/teadsBidAdapter'; //Teads
// import 'prebid.js/modules/adagioBidAdapter'; //Adagio
// import 'prebid.js/modules/ixBidAdapter'; //Index Exchange
// import 'prebid.js/modules/sharethroughBidAdapter'; //Share Through
// import 'prebid.js/modules/onetagBidAdapter'; //OneTag
// import 'prebid.js/modules/criteoBidAdapter'; //Criteo
// import 'prebid.js/modules/pubmaticBidAdapter'; //Pubmatic
// import 'prebid.js/modules/justpremiumBidAdapter'; //Just Premium
// //import 'prebid.js/modules/'; //One Fifty Media
// import 'prebid.js/modules/adfBidAdapter'; //Ad Form

// //Anayltic Adapters
// import 'prebid.js/modules/adagioAnalyticsAdapter'; //Adagio Analytics

// //Recommended modules
// import 'prebid.js/modules/consentManagement'; //Consent Mangement
// import 'prebid.js/modules/gdprEnforcement'; //GDPR Enforcement

// //User ID Modules
// import 'prebid.js/modules/lotamePanoramaIdSystem'; //Lotame ID
// import 'prebid.js/modules/id5IdSystem'; //ID5 ID
// import 'prebid.js/modules/identityLinkIdSystem'; //Identity Link ID

// //General Modules
// //import 'prebid.js/modules/currency'; //currency
// import 'prebid.js/modules/priceFloors'; //Price Floors

import React from "react";

import { TrackingProvider } from "./src/context/TrackingContext";

export const wrapRootElement = ({ element }) => {
  return <TrackingProvider>{element}</TrackingProvider>;
};
