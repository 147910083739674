import React from "react";
import {
  DebugModeLog,   
  runAbTest,
} from "../utils";

const defaultTracking = {
  utm_source: 'nosource',
  utm_source2: false,
  utm_campaign: false,
  utm_medium: false,
  abtest: false
}

const TrackingContext = React.createContext( defaultTracking )

class TrackingProvider extends React.Component {

  tracking = {
    utm_source: 'nosource',
    utm_source2: false,
    utm_campaign: false,
    utm_medium: false,
    abtest: false
  }

  constructor() {
    super();
    this.setTracking();
    this.checkLocalStorage();
  }

  setTracking() {
    if( !window.location.search ) return;

    const params = new URLSearchParams(window.location.search );

    let abtestVal = params.get('abtests');
    if (abtestVal) {
      DebugModeLog( abtestVal );
      return;
    } else {
      abtestVal = runAbTest(0.25, 0.50, 0.75);
      DebugModeLog( abtestVal );
    }
        
    

    this.tracking = {
      utm_source: params.get('utm_source'),
      utm_source2: params.get('utm_source2'),
      utm_campaign: params.get('utm_campaign'),
      utm_medium: params.get('utm_medium'),
      abtest: abtestVal
    }

    for ( const key in this.tracking ) {
      localStorage.setItem( key, this.tracking[key] );
    }

  }
  

  checkLocalStorage() {
    if( window.location.search ) return;

    for ( const key in this.tracking ) {
      if( localStorage.getItem( key ) ) this.tracking[key] = localStorage.getItem( key );
    }
  }


  

  render() {
    const { children } = this.props;
    const { utm_source,
            utm_source2,
            utm_campaign,
            utm_medium, 
            abtest } = this.tracking;
       
    return (
      <TrackingContext.Provider
        value={{
          utm_source,
          utm_source2,
          utm_campaign,
          utm_medium,
          abtest
        }}
        >
          {children}
        </TrackingContext.Provider>
    )
  }
}
export default TrackingContext
export { TrackingProvider }
